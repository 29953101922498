export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'AirplayIcon',
  },
  {
    title: 'Clientes',
    permission: 'clients',
    route: 'clients',
    icon: 'UsersIcon',
  },
  {
    title: 'Artículos',
    permission: 'products',
    route: 'products',
    icon: 'ShoppingBagIcon',
  },
  { title: 'spacing' },
  {
    title: 'Cotizaciones',
    permission: 'quotations',
    route: 'quotations',
    icon: 'FileIcon',
  },
  {
    title: 'Facturas recurrentes',
    permission: 'recurring-invoices',
    route: 'recurring-invoices',
    icon: 'FilePlusIcon',
  },
  {
    title: 'Facturas',
    permission: 'invoices',
    route: 'invoices',
    icon: 'TagIcon',
    type: 'invoice',
    action: 'new-document',
    actionIcon: 'path',
  },
  {
    title: 'Tiquetes',
    permission: 'tickets',
    route: 'tickets',
    icon: 'FileTextIcon',
  },
  {
    title: 'Notas',
    permission: 'notes',
    route: 'notes',
    icon: 'FileMinusIcon',
  },
  {
    title: 'Simplificado',
    permission: 'simples',
    route: 'simples',
    icon: 'FileIcon',
  },
  { title: 'spacing' },
  {
    title: 'Recep. Docs',
    permission: 'doccument-receptions',
    route: 'doccument-receptions',
    icon: 'BookIcon',
  },
  {
    title: 'Pagos',
    permission: 'payments',
    route: 'payments',
    icon: 'CreditCardIcon',
  },
  {
    title: 'Gastos',
    permission: 'expenses',
    route: 'expenses',
    icon: 'HeartIcon',
  },
  {
    title: 'Reportes',
    permission: 'reports',
    route: 'reports',
    icon: 'ActivityIcon',
    children: [
      {
        title: 'Tiquetes',
        permission: 'report-tickets',
        route: 'report-tickets',
      },
      {
        title: 'Facturas',
        permission: 'report-invoices',
        route: 'report-invoices',
      },
      {
        title: 'Notas',
        permission: 'report-notes',
        route: 'report-notes',
      },
      {
        title: 'Gastos',
        permission: 'report-expenses',
        route: 'report-expenses',
      },
      {
        title: 'Productos',
        permission: 'report-products',
        route: 'report-products',
      },
      {
        title: 'Recep. Docs',
        permission: 'report-receptions',
        route: 'report-receptions',
      },
    ],
  },
  { title: 'spacing' },
  {
    title: 'Mis compañías',
    permission: 'my-companies',
    route: 'my-companies',
    icon: 'BoxIcon',
  },
  {
    title: 'Administrador',
    permission: 'administrator',
    route: 'administrator',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Usuarios',
        permission: 'users',
        route: 'users',
      },
      {
        title: 'Compañías',
        permission: 'companies',
        route: 'companies',
      },
      {
        title: 'Anuncios',
        permission: 'advertisements',
        route: 'advertisements',
      },
    ],
  },
]
